<script setup lang="ts">
import { onBeforeMount } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { watchOnce } from '@vueuse/core'
import { CbProvider, CbAlertField } from '@cluber/carabiner'

import { useAppConfigStore } from '@core/store/app'
import { manageUserAuth, useApiAuthStore } from '@core/modules/Auth/store'
import { useTailwindBreakpoints } from '@cluber/tools-composables'
import { useBrowserTabTitle } from '@libs/fsc/composables/useBrowserTabTitle'
import useGetClubLocale from '@libs/fsc/composables/useGetClubLocale/useGetClubLocale'

import Navigation from '@dashboard/components/Navigation/Navigation.vue'
import DevTools from '@core/components/DevTools/DevTools.vue'
import { isProduction, notProductionEnvironment, isStaging } from '@core/utils/env'
import tailwindConfig from '@coreRoot/tailwind.config'

import { apiURL } from './config'
import CookieNotice from './components/CookieNotice/CookieNotice.vue'
import ZohoChat from './components/ZohoChat/ZohoChat.vue'

// ***
// DEFAULTS
// ***
const appConfig = useAppConfigStore()
const apiAuthStore = useApiAuthStore()
const i18nConfig = useI18n() as ReturnType<typeof useI18n>
const route = useRoute()
const { setLocale } = useGetClubLocale()

// Sets title based on current route
useBrowserTabTitle(route)

const { isSmaller } = useTailwindBreakpoints(tailwindConfig)

// ***
// USER AUTH
// ***
const { initializeUserAuth } = manageUserAuth()

onBeforeMount(() => {
  initializeUserAuth()
})

const authToken = () => apiAuthStore.$state.postAuthToken?.token || ''

watchOnce(authToken, (current) => {
  if (current) setLocale()
})

</script>

<template>
  <main
    :class="[
      'App-main',
      route.meta.mainClasses,
      isSmaller('md') ? { 'mt-16 md:mt-20': route.meta.navigationType === 'dashboard' } : 'navigation-margin-left',
    ]"
  >
    <div
      v-if="isStaging && !appConfig.$state.isStagingBannerHidden"
      class="text-center w-6/12 m-auto fixed top-0 left-0 right-0 z-50"
    >
      <div class="bg-warning-300 inline-flex px-12 py-1 rounded-sm font-bold">
        <i class="bi bi-exclamation-triangle-fill mr-2" /> STAGING ENVIRONMENT
      </div>
    </div>
    <CookieNotice />
    <ZohoChat v-if="isProduction" />
    <DevTools v-if="notProductionEnvironment" />
    <router-view v-slot="{ Component }">
      <!-- @vue-ignore There's a typescript error that doesn't allow to pass proper-->
      <CbProvider
        :key="(i18nConfig.locale.value as string)"
        :i18n-config="i18nConfig"
        :auth-token="authToken"
        :api-urls="apiURL"
        :route="route"
      >
        <CbAlertField />
        <transition
          mode="out-in"
          :duration="200"
          enter-active-class="animate__animated animate__fadeIn animate__faster"
          leave-active-class="animate__animated animate__fadeOut animate__faster"
        >
          <component :is="Component" />
        </transition>
      </CbProvider>
    </router-view>
  </main>
  <Navigation
    :responsive-menu="isSmaller('md')"
  />
  <!-- <Footer component with v-if with meta router params to hide or show /> -->
</template>

<style lang="postcss">
.App-main {
  &.navigation-margin-left {
    margin-left: var(--cluber-app-nav-width);
  }
}
</style>
