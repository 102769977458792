import { EndpointConfig } from '@core/store/apiRequest/declarations'

export const postUserToken: EndpointConfig = {
  storeKey: 'postAuthToken',
  url: '/api/authentication_token',
}

export const getUserLegacyLogout: EndpointConfig = {
  storeKey: 'getUserLegacyLogout',
  url: '/logout',
}

export const getUserProfile: EndpointConfig = {
  storeKey: 'getUserProfile',
  url: '/api/profile',
}
