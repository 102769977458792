export const apiURL = {
  main: import.meta.env.VITE_ENDPOINT_MAIN,
  legacy: import.meta.env.VITE_ENDPOINT_LEGACY,
  origin: window.location.origin,
}

export const allowMSW = import.meta.env.VITE_ALLOW_MSW === 'allow'

export const timeTime = {
  url: import.meta.env.VITE_TIMETIME_ENV_URL,
  tenant: import.meta.env.VITE_TIMETIME_ENV_TENANT,
}

export const cluberLegacy = {
  baseUrl: import.meta.env.VITE_CLUBER_LEGACY_URL,
}

export const siguetuligaLegacy = {
  baseUrl: import.meta.env.VITE_SIGUETULIGA_ENV_URL,
}

export const zoho = {
  helpFormUrl: import.meta.env.VITE_ZOHO_CLUBER_HELP_FORM_ENV_URL,
  incidenceFormUrl: import.meta.env.VITE_ZOHO_CLUBER_INCIDENCE_FORM_ENV_URL,
  cluberFaqUrl: import.meta.env.VITE_ZOHO_FAQ_ENV_URL,
}

export const cluber = {
  url: import.meta.env.VITE_CLUBER_PAGE_URL,
  cluberTutorialsUrl: import.meta.env.VITE_CLUBER_TUTORIALS_ENV_URL,
}
