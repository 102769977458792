import {
  type RouteRecordRaw,
} from 'vue-router'

export const legacyRoutes : RouteRecordRaw[] = [
  {
    path: '/persons',
    redirect: '/dashboard/profile-selector',
  },
  {
    path: '/login',
    redirect: {
      name: 'authLogin',
    },
  },
  {
    path: '/logout',
    redirect: {
      name: 'authLogout',
    },
  },
  /// Dashboard
  {
    path: '/clubes/:clubHash/ver',
    redirect: to => ({ path: `/dashboard/manager/${to.params.clubHash as string}/overview` }),
  },
  // {
  //   path: '/clubes/:clubHash/dashboard',
  //   redirect: to => ({ path: `/dashboard/manager/${to.params.clubHash as string}/access-control` }),
  // },
  // {
  //   path: '/clubes/:clubHash/items',
  //   redirect: to => ({ path: `/dashboard/manager/ecommerce/${to.params.clubHash as string}/campaigns/list` }),
  // },
  // {
  //   path: '/clubes/:clubHash/items/:campaignHash/ver',
  //   redirect: to => ({ path: `/dashboard/manager/ecommerce/${to.params.clubHash as string}/campaign/${to.params.campaignHash as string}` }),
  // },
  {
    path: '/clubes/:clubHash/messages',
    redirect: to => ({ path: `/dashboard/manager/messages/${to.params.clubHash as string}/all-messages` }),
  },
  {
    path: '/socios/:userId/clubes/:clubHash/messages',
    redirect: to => ({ path: `/dashboard/member/${to.params.clubHash as string}/messages` }),
  },
  {
    path: '/padres/:userId/clubes/:clubHash/messages',
    redirect: to => ({ path: `/dashboard/member/${to.params.clubHash as string}/messages` }),
  },
  // Public portal
  // {
  //   path: '/clubes/:clubHash/portal',
  //   redirect: to => ({ path: `/portal/${to.params.clubHash as string}` }),
  // },
  // Profile selection
  {
    path: '/padres',
    redirect: '/dashboard/profile-selector',
  },
  {
    path: '/socios',
    redirect: '/dashboard/profile-selector',
  },
]
