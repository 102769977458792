<script setup lang="ts">
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { type CookieSetOptions } from 'universal-cookie'

import { CbAccordion, CbButton, CbCard } from '@cluber/carabiner'
import { useCookies } from '@vueuse/integrations/useCookies'

// ***
// DEFAULTS
// ***
const { t } = useI18n()

const isManagingPreferences = ref(false)
// The max amount of time a cookie can be set is around 400 days.
// Expiration time must be expressed in seconds
const COOKIE_MAX_EXPIRATION_TIME_SECONDS = 400 * 24 * 60 * 60

const { get, set } = useCookies(['cookieConsent'])
const cookieSetOptions : CookieSetOptions = {
  maxAge: COOKIE_MAX_EXPIRATION_TIME_SECONDS,
  path: '/',
}
// ***
// COOKIE CONFIGURATION
// ***

const enableFunctional = ref(true)
const enableAnalytical = ref(true)

function acceptCookies() {
  set('cookieConsent', 'true', cookieSetOptions)
  const enabledCookies = [
    ...(enableFunctional.value ? ['"functionals"'] : []),
    ...(enableAnalytical.value ? ['"analytics"'] : []),
  ]
  set('cookieControlPrefs', `[${enabledCookies.join(',')}]`, cookieSetOptions)
}

</script>

<template>
  <Teleport to="body">
    <Transition
      mode="out-in"
      name="fade"
    >
      <CbCard
        v-if="!get('cookieConsent')"
        class="fixed p-5 left-10 mr-10 bottom-[5rem] z-[100] max-w-[30rem]"
        data-testid="cookieNotice"
      >
        <h5 class="font-display font-bold text-base">
          <span class="mr-2">🍪</span>
          <span>{{ t('We use cookies!') }}</span>
        </h5>
        <i18n-t
          tag="p"
          class="text-sm my-4"
          keypath="We use strictly necessary cookies and third-party cookies for analytical purposes. You can accept all cookies by clicking the {accept} button or configure them by clicking {customize}. {information}"
        >
          <template #accept>
            <span class="font-bold">{{ t('Accept') }}</span>
          </template>
          <template #customize>
            <span class="font-bold">{{ t('Manage your preferences') }}</span>
          </template>
          <template #information>
            <a
              class="font-bold text-primary-500 hover:underline"
              target="_blank"
              no-referrer
              href="/es/politica-privacidad/cookies"
            >
              {{ t('More information') }}
            </a>
          </template>
        </i18n-t>
        <div class="flex justify-start">
          <CbAccordion
            custom-header-classes=" "
            custom-body-classes=" "
            hide-chevron
            :force-always-expanded="isManagingPreferences"
            @expanded="() => isManagingPreferences = true"
            @collapsed="() => isManagingPreferences = false"
          >
            <template #title>
              <span
                :class="[
                  'font-bold text-sm underline',
                  { 'text-base-800 no-underline': isManagingPreferences },
                ]"
              >
                {{ t('Manage your preferences') }}
              </span>
            </template>
            <template #default>
              <div class="text-sm flex flex-col gap-y-2 pt-2">
                <FormKit
                  type="checkbox"
                  :model-value="true"
                  disabled
                  :label="t('Essential cookies')"
                />
                <FormKit
                  v-model="enableFunctional"
                  type="checkbox"
                  :label="t('Functional cookies')"
                />
                <FormKit
                  v-model="enableAnalytical"
                  type="checkbox"
                  :label="t('Analytical cookies')"
                />
              </div>
            </template>
          </CbAccordion>
          <CbButton
            class="ml-auto mt-auto"
            color="primary"
            mode="filled"
            size="sm"
            data-testid="acceptCookies"
            @click="acceptCookies"
          >
            {{ t('Accept') }}
          </CbButton>
        </div>
      </CbCard>
    </Transition>
  </Teleport>
</template>
