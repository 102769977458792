import { ModuleRoutes } from '@core/declarations/router'

const ShortenedNamePortal = () => import('@portal/modules/PublicWebsite/views/ShortenedNamePortal/ShortenedNamePortal.vue')
const Default = () => import('@portal/modules/PublicWebsite/views/Default/Default.vue')

const routes: ModuleRoutes = {
  name: 'PublicWebsite',
  routes: [
    {
      path: '/clubes/:clubHash/portal',
      name: 'publicWebsite',
      component: Default,
      meta: {
        tabTitle: 'Club public portal',
        navigationType: 'clean',
      },
    },
    {
      path: '/p/:clubShortName',
      name: 'publicWebsiteShortName',
      component: ShortenedNamePortal,
      meta: {
        tabTitle: 'Club public portal',
        navigationType: 'clean',
      },
    },
  ],
}

export default routes
