import {
  ref,
  computed,
  onMounted,
  onBeforeUnmount,
} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { MutationType } from 'pinia'
import { useCluberLocalStorage } from '@cluber/tools-composables'

import { type UserDataLegacy, type ExcludeExternalRole } from '@libs/fsc/types/user'
import { type ClubGroupLegacy } from '@libs/fsc/types/legacy'
import { ApiRequestStatus } from '@core/store/apiRequest/declarations'
import { useApiProfileStore } from '@dashboard/modules/ProfileSelector/store'
import { getLegacyPeopleGroupByClub } from '@dashboard/modules/ProfileSelector/api'

import { translationByRole } from '@libs/fsc/utils/roles'
import { devLogger } from '@libs/fsc/utils/logger'

const currentUser = ref<UserDataLegacy>()
const isRequestingUserProfileDataFromLegacy = ref(false)
const { storage } = useCluberLocalStorage<{
  legacyPeopleGroupByClub?: ClubGroupLegacy,
  impersonatedUser?: UserDataLegacy,
  isImpersonated?: boolean,
}>({
  legacyPeopleGroupByClub: undefined,
}, { mergeDefaults: true })
const isImpersonated = ref(storage.value.isImpersonated)

export function useGetLegacyPeopleGroupByClub() {
  const route = useRoute()
  const router = useRouter()
  const currentRoute = ref()
  const peopleByClubs = ref<ClubGroupLegacy | undefined>(storage.value.legacyPeopleGroupByClub)

  // ***
  // RETRIEVE CLUB GROUP DATA
  // ***

  function getCurrentClubPersons() {
    if (!peopleByClubs.value) return []
    const currentClub = (Object.values(peopleByClubs.value) as unknown as ClubGroupLegacy[]).filter((item) => (
      item.club?.id === route.params.clubHash
    ))
    return currentClub[0].persons
  }

  // ***
  // PARSE PROFILE DATA
  // ***

  function adminImpersonateCurrentUser(impersonatedUser: UserDataLegacy) {
    isImpersonated.value = true
    currentUser.value = impersonatedUser
    storage.value.isImpersonated = true
    storage.value.impersonatedUser = impersonatedUser
  }

  // Automatically parses currentUser
  function setCurrentUserData(
    { role, clubHash }: { role: ExcludeExternalRole, clubHash: string | string[] },
  ) {
    const userData = ref<UserDataLegacy>()

    if (isImpersonated.value) {
      return storage.value?.impersonatedUser
    }

    if (!peopleByClubs.value || !role || !clubHash) return userData

    const clubs = (Object.values(peopleByClubs.value) as unknown as ClubGroupLegacy[])
    const currentFiltered = clubs.filter((item) => item.club?.id === clubHash)[0]

    if (!currentFiltered) {
      router.push({ name: 'profileSelector' })
      return userData
    }

    const currentPerson = currentFiltered.persons.find(item => (
      item.type === translationByRole(role).toLowerCase()
    ))

    userData.value = {
      clubName: currentFiltered.club.name,
      clubLogo: currentFiltered.club.image,
      name: currentPerson?.name,
      phone: currentPerson?.phone,
      email: currentPerson?.email,
      lastname: currentPerson?.surname,
      id: currentPerson?.id,
      subsidies: currentPerson?.subsidies,
    }

    currentUser.value = userData.value
    return userData
  }

  // ***
  // REQUEST PROFILE DATA
  // ***
  const apiProfileStore = useApiProfileStore()

  const {
    sendRequest,
    checkRequestStatus,
    $state,
  } = apiProfileStore

  function deleteUserClubPersons() {
    $state.getLegacyPeopleGroupByClub = undefined
    storage.value = null
  }

  function getUserProfileDataFromLegacy(force?: 'force') {
    const isForced = force === 'force'
    if ((route.path.includes('auth') || route.path === '/') && !isForced) return
    currentRoute.value = route
    isRequestingUserProfileDataFromLegacy.value = true

    const legacyGroup = $state.getLegacyPeopleGroupByClub || storage.value.legacyPeopleGroupByClub
    const emptyLegacyGroup = (legacyGroup === undefined) || (Object.values(legacyGroup).length === 0)

    if (isForced || emptyLegacyGroup || !isRequestingUserProfileDataFromLegacy.value) {
      sendRequest({
        endpointConfig: getLegacyPeopleGroupByClub,
        endpointName: 'legacy',
      })
    } else {
      // @ts-expect-error - Tried to solve without success
      peopleByClubs.value = legacyGroup
      setCurrentUserData({
        clubHash: route.params.clubHash as string,
        role: route.meta.roleKind as ExcludeExternalRole,
      })
    }
  }

  const unsubscribeGetGroupedClubs = apiProfileStore.$subscribe((mutation) => {
    if (mutation.type === MutationType.patchObject) {
      const userGroupedByClub = checkRequestStatus(getLegacyPeopleGroupByClub.storeKey, mutation)

      if (userGroupedByClub === ApiRequestStatus.Success) {
        isRequestingUserProfileDataFromLegacy.value = false
        const { data } = apiProfileStore.convertApiDataFromStore<ClubGroupLegacy>(getLegacyPeopleGroupByClub.storeKey)
        if (data) {
          peopleByClubs.value = data
          storage.value.legacyPeopleGroupByClub = data

          // This condition it's for routes that doesn't has implicit role or clubHash data (you can call setCurrentUserData)
          // from component
          if ((!route.params.clubHash || !route.meta.roleKind) && route.name !== 'profileSelector') {
            devLogger('You are trying to load the user profile without roleKind associated to meta property on route config or you are missing the clubHash', 'warn')
          }
          if (route.params.clubHash && route.meta.roleKind) {
            setCurrentUserData({
              clubHash: route.params.clubHash as string,
              role: route.meta.roleKind as ExcludeExternalRole,
            })
          }
        }
      }

      if (userGroupedByClub === ApiRequestStatus.Error) {
        isRequestingUserProfileDataFromLegacy.value = false
        const {
          data,
        } = apiProfileStore.convertApiDataFromStore<{ error: { code: number, message: string } }>(
          getLegacyPeopleGroupByClub.storeKey,
        )
        if (data?.error) {
          devLogger(data?.error.message, 'error')
          storage.value.legacyPeopleGroupByClub = undefined
          if (data?.error.code === 401 && !route.path.includes('auth')) {
            const fromQuery = route.query?.from ? JSON.stringify(route.query.from) : undefined

            router.push({
              name: 'authLogout',
              query: {
                keepCurrentUrl: `${!fromQuery}`,
                expiredToken: 'true',
                from: fromQuery,
              },
            })
          }
        }
      }
    }
  })

  onMounted(() => {
    if (isImpersonated.value) {
      currentUser.value = storage.value?.impersonatedUser
      return
    }
    if (!isRequestingUserProfileDataFromLegacy.value) {
      if (!peopleByClubs.value) getUserProfileDataFromLegacy()
      else if (route.params.clubHash && route.meta.roleKind) {
        setCurrentUserData({
          clubHash: route.params.clubHash,
          role: route.meta.roleKind as ExcludeExternalRole,
        })
      }
    }
  })

  // This runs if app gets 401 and user has to log again. Requesting again profiles
  // watch(() => authState.postAuthToken?.token, (current) => {
  //   console.log('THIS IS RUN', current)
  //   if (current && !peopleByClubs.value) {
  //     getUserProfileDataFromLegacy('force')
  //   }
  // })

  onBeforeUnmount(unsubscribeGetGroupedClubs)

  return {
    getUserProfileDataFromLegacy,
    setCurrentUserData,
    getCurrentClubPersons,
    deleteUserClubPersons,
    adminImpersonateCurrentUser,
    peopleByClubs: computed(() => peopleByClubs.value),
    currentUser: computed(() => currentUser.value),
  }
}
